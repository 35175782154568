@use '../../../variables' as var;

.panelContainer {
  min-width: 20px;
  min-height: 20px;
  position: relative;
  perspective: 100px;
  transition: width 500ms, height 500ms;
}

.borderCornersContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  transition: transform 200ms;
}

.borderCorners {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 0 0,
    linear-gradient(to right, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 0 100%,
    linear-gradient(to left, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 100% 0,
    linear-gradient(to left, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 100% 100%,
    linear-gradient(to bottom, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 0 0,
    linear-gradient(to bottom, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 100% 0,
    linear-gradient(to top, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 0 100%,
    linear-gradient(to top, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  animation: expand 500ms ease-in-out forwards;
}

.panel {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(250, 250, 250, 0.1);
  opacity: 0;
  overflow: hidden;
  position: relative;
  animation: fadeInBgSaturation 400ms linear 400ms forwards;
}

.panelContent {
  width: 100%;
  height: 100%;
  filter: opacity(0);
  animation: fadeIn 400ms ease-in-out 400ms forwards;

  a {
    color: rgba(250, 250, 250, 0.9);
  }
}

// animations
@keyframes fadeInBgSaturation {
  from {
    backdrop-filter: saturate(0);
    opacity: 0;
  }

  50% {
    backdrop-filter: saturate(4.6);
    opacity: 1;
  }

  to {
    backdrop-filter: saturate(2);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    filter: opacity(0) blur(20px);
  }

  to {
    filter: opacity(1) blur(0px);
  }
}

@keyframes expand {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

// breakpoints
@include var.breakpoint(375px) {
  .panelContainer {
    width: 260px !important;
    height: 260px !important;
    font-size: 0.9em;
  }
}
