.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  overflow: hidden;
}

.directory {
  span {
    text-transform: uppercase;
    font-size: 1.5em;
    margin-bottom: 10px;
  }
}
