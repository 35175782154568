.canvas {
  z-index: -1;
  position: absolute;
  overflow: hidden;
  width: 100vw !important;
  height: 100vh !important;
}

.loading {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;

  div {
    width: 200px;
    height: 15px;
    background-color: rgba(250, 250, 250, 0.7);
    opacity: 1;
  }
}

.loaded {
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms, visibility 200ms;
}
