.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../images/bg/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar {
  position: relative;
  height: 30px;
  width: 215px;
  z-index: 3;
  display: flex;
  align-items: center;
  div {
    position: absolute;
    left: 5%;
    height: 15px;
    width: 0;
    background-color: rgba(250, 250, 250, 0.3);
    transition: width 200ms;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 215px;
    background: linear-gradient(to right, rgba(250, 250, 250, 0.8) 1px, transparent 1px) 0 0,
      linear-gradient(to right, rgba(250, 250, 250, 0.8) 1px, transparent 1px) 0 100%,
      linear-gradient(to left, rgba(250, 250, 250, 0.8) 1px, transparent 1px) 100% 0,
      linear-gradient(to left, rgba(250, 250, 250, 0.8) 1px, transparent 1px) 100% 100%,
      linear-gradient(to bottom, rgba(250, 250, 250, 0.8) 1px, transparent 1px) 0 0,
      linear-gradient(to bottom, rgba(250, 250, 250, 0.8) 1px, transparent 1px) 100% 0,
      linear-gradient(to top, rgba(250, 250, 250, 0.8) 1px, transparent 1px) 0 100%,
      linear-gradient(to top, rgba(250, 250, 250, 0.8) 1px, transparent 1px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 5px 5px;
  }
}

.enter {
  opacity: 0;
}

.enterActive {
  opacity: 1;
  transition-delay: 500ms;
  transition-duration: 1000ms;
  transition-property: opacity;
}

.exit {
  opacity: 1;
}

.exitActive {
  opacity: 0;
  transition-delay: 500ms;
  transition-duration: 1000ms;
  transition-property: opacity;
}
