@use '../../../variables' as var;

$indent: 256px;
$height: 64px;
$backdrop-filter: saturate(2);
$border: 1px solid white;

.panel {
    position: relative;
    width: 100%;
    height: $height;
  }
  
  .topLeft {
    position: absolute;
    width: calc(100% - $indent);
    height: calc($height / 2);
    border-bottom: $border;
    backdrop-filter: $backdrop-filter;
  }
  
  .topRight {
    position: absolute;
    right: 0;
    width: $indent;
    height: calc($height / 2);
    backdrop-filter: $backdrop-filter;
  }
  
  .bottomRight {
    position: absolute;
    right: 0;
    top: calc($height / 2);
    width: $indent;
    height: calc($height / 2);
    backdrop-filter: $backdrop-filter;
    transform: translateX(16px) skew(45deg);
    border-bottom: $border;
    border-left: $border;
  }
  
  .container {
    position: absolute;
    display: flex;
    align-items: center;
    left: calc(100% - $indent);
    right: 0;
    top: calc($height / 2);
    height: calc(100% - $height);
    padding: 1em 2.25em;
  }
  