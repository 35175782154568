@font-face {
  font-family: 'supplyMonoUltralight';
  src: url('./fonts/PPSupplyMono-Ultralight.otf');
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'supplyMonoUltralight';
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(250, 250, 250, 0.9);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  width: 100%;
  height: 100%;
  /* background-image: linear-gradient(to right, rgba(250, 250, 250, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(250, 250, 250, 0.1) 1px, transparent 1px);
  background-size: 50px 50px; */
}
