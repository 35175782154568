@use '../../../variables' as var;

$indent: 256px;
$option: 56px;
$height: 64px;
$backdrop-filter: saturate(2);
$border: 1px solid white;
$activeOption: 148px;
$idleOption: 86px;

.panel {
  position: relative;
  width: 100%;
  height: $height;
}

.bottomRight {
  position: absolute;
  bottom: 0;
  left: $indent;
  width: calc(100% - ($indent));
  left: $indent;
  height: calc($height / 2);
  border-top: $border;
  backdrop-filter: $backdrop-filter;
}

.bottomLeft {
  position: absolute;
  bottom: 0;
  left: 0;
  width: $indent;
  height: calc($height / 2);
  backdrop-filter: $backdrop-filter;
}

.topLeft {
  position: absolute;
  left: 0;
  width: $indent;
  height: calc($height / 2 - 1px);
  backdrop-filter: $backdrop-filter;
  transform: translateX(-16px) skew(45deg);
  border-top: $border;
  border-right: $border;
}

.options {
  height: calc($height / 2);
  display: flex;
}

.option {
  border-right: $border;
  border-top: $border;
  box-sizing: border-box;
  height: calc($height / 2);
  width: $option;
  backdrop-filter: $backdrop-filter brightness(80%);
  transform: translateX(calc($indent - 16px)) skew(45deg);
  cursor: pointer;
  transition: width 200ms;
}

.optionContentContainer {
  position: absolute;
  display: flex;
  height: calc($height / 2);
  width: 100%;
  top: 0;
  left: 0;
}

.optionContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc($height / 2);
  width: $option;
  text-transform: uppercase;
  font-size: 0.8em;
  transform: translateX(calc($indent - 16px));
  cursor: pointer;
  transition: width 200ms;
}

.activeOption {
  width: $activeOption;
  backdrop-filter: saturate(4);
}

.activeOptionName {
  width: $activeOption;
}

.idleOption {
  width: $idleOption;
}

.idleOptionName {
  width: $idleOption;
}

@include var.breakpoint(667px) {
  $indent: 56px;
  $activeOption: 128px;
  $idleOption: 66px;
  .topLeft {
    width: $indent;
  }
  .bottomLeft {
    width: $indent;
  }
  .bottomRight {
    left: $indent;
    width: 100%;
  }
  .option {
    transform: translateX(calc($indent - 16px)) skew(45deg);
  }
  .optionContent {
    font-size: 0.7em;
    transform: translateX(calc($indent - 16px));
  }
  .activeOption,
  .activeOptionName {
    width: $activeOption;
  }
  .idleOption,
  .idleOptionName {
    width: $idleOption;
  }
}
