.container {
  position: absolute;
  top: 40px;
}

.terminalDirectory {
  font-size: 1.2em;
  text-transform: uppercase;
  color: rgba(250, 250, 250, 0.9);
}

.terminal {
  text-transform: uppercase;
  width: 210px;
  font-size: 0.8em;
  color: rgba(250, 250, 250, 0.9);
  ::selection {
    background: rgba(250, 250, 250, 0.9);
    color: black;
  }
  .terminalInput {
    display: block;
    outline: none;
    caret-color: transparent;
    &:before {
      content: '\\\\';
    }
  }
  .terminalInput:focus {
    &:after {
      content: '';
      border-right: 8px solid rgba(250, 250, 250, 0.9);
      animation: flash 1s steps(1) infinite;
    }
  }
}

@keyframes flash {
  50% {
    border-color: transparent;
  }
}
