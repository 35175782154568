.container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.links {
  display: flex;
  flex-direction: column;
  position: relative;

  .link {
    height: 20px;
    margin: 5px 10px;
    a {
      text-decoration: none;
      display: block;
      padding: 5px 10px;
      transition: opacity 200ms;
    }
  }
}

.linkBorder {
  width: 100%;
  height: 25px;
  background: linear-gradient(to right, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 0 0,
    linear-gradient(to right, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 0 100%,
    linear-gradient(to left, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 100% 0,
    linear-gradient(to left, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 100% 100%,
    linear-gradient(to bottom, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 0 0,
    linear-gradient(to bottom, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 100% 0,
    linear-gradient(to top, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 0 100%,
    linear-gradient(to top, rgba(250, 250, 250, 0.6) 1px, transparent 1px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 5px 5px;
  position: absolute;
  z-index: -1;
  opacity: 0;
  filter: blur(5px);
  transition: transform 200ms, opacity 200ms, filter 300ms;
}
