@use '../../variables' as var;

.container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.panelContainer {
  height: calc(100% - 128px);
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.panel {
  position: relative;
  transition: width 300ms, height 300ms;
  &:hover {
    width: 420px !important;
    height: 420px !important;

    .name {
      opacity: 0;
    }
    .content {
      opacity: 1;
    }
  }
}

.name {
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  font-size: 1.2em;
  transition: opacity 200ms;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-size: 13px;
  opacity: 0;
  transition: opacity 200ms;

  a {
    padding: 30px 0 10px 0;
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  p {
    margin: 10px;
    font-size: 13px;
  }
  svg {
    width: 14px;
    padding-right: 3px;
    fill: white;
    circle {
      fill: white;
    }
    g {
      stroke: white;
    }
  }
}

.description {
  ul {
    list-style: none;
    font-size: 13px;
    padding: 0;
    li {
      overflow: hidden;
      inline-size: 400px;
      padding: 5px 10px;
    }
  }
}

.stack {
  ul {
    display: flex;
    list-style: none;
    font-size: 14px;
    padding: 0 10px;
    li {
      padding: 4px;
      display: flex;
      align-items: center;
    }
  }
}

.bgImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 300%;
  z-index: -1;
  filter: blur(1.5px) opacity(0.2);
  animation: scrollbg 10s ease-in-out infinite alternate;
}

// animations
@keyframes scrollbg {
  from {
    transform: translate(0%, 0%);
  }
  to {
    transform: translate(-60%, -10%);
  }
}

// breakpoints
@include var.breakpoint(768px) {
  .panel {
    width: 260px !important;
    height: 260px !important;
    &:hover {
      width: 320px !important;
      height: 320px !important;
    }
  }
  .description {
    ul {
      li {
        inline-size: 300px;
        font-size: 0.9em;
      }
    }
  }
  .stack {
    ul {
      flex-wrap: wrap;
      justify-content: center;
      inline-size: 300px;
      li {
        font-size: 0.9em;
      }
    }
  }
}

@include var.breakpoint(667px) {
  .panelContainer {
    flex-direction: column;
  }
  .panel {
    width: 240px !important;
    height: 240px !important;
    &:hover {
      width: 300px !important;
      height: 300px !important;
    }
  }
  .description {
    ul {
      li {
        inline-size: 280px;
        font-size: 0.9em;
      }
    }
  }
  .stack {
    ul {
      flex-wrap: wrap;
      justify-content: center;
      inline-size: 280px;
      li {
        font-size: 0.9em;
      }
    }
  }
}
@include var.breakpoint(390px) {
  .panel {
    width: 220px !important;
    height: 220px !important;
    &:hover {
      width: 280px !important;
      height: 280px !important;
    }
  }
  .description {
    ul {
      li {
        inline-size: 260px;
        font-size: 0.9em;
      }
    }
  }
  .stack {
    ul {
      flex-wrap: wrap;
      justify-content: center;
      inline-size: 260px;
      li {
        font-size: 0.9em;
      }
    }
  }
}
